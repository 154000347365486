import {useState, useRef, useEffect  } from 'react'
import { Route, Routes } from 'react-router-dom'
import Login from './pages/Login'
import Layout from './components/layout/Layout'
import Dashboard from './components/dashboard/index'
import ProposedSaleReport from './components/proposedSaleReport'
import FinalSaleReport from './components/finalSaleReport'
import Reports from './components/reports'
import Associations from './components/admin/associations'
import PrivateSaleReasonReport from './components/privateSaleReasonReport'
import MelloRoos from './components/melloRoos'
import MKRLocalObligors from './components/mkrLocalObligors'
import MKRLocalObligorsYFS from './components/mkrLocalObligors/mkrLocalObligorsYFS'
import MKRAuthorityIssue from './components/mkrAuthorityIssue'
import MKRAuthorityIssueYFS from './components/mkrAuthorityIssue/mkrAuthorityIssueYFS'
import MelloRoosYFS from './components/melloRoos/melloRoosYFS'
import DrawOnReserve from './components/drawOnReserve'
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import IssuerRegistrationForm from './pages/IssuerRegistrationForm'
//import FilerRegistrationForm from './pages/FilerRegistrationForm'
import { UserContext } from './UseContext'
import { useMsal } from "@azure/msal-react";
import { useIdleTimer } from 'react-idle-timer';
import { toast } from 'react-toastify';
import {Modal, Button} from 'react-bootstrap'
import Logout from './components/logout'
import AdtrYFS from './components/adtr/adtrYFS'
import Adtr from './components/adtr'
import AdtrBatch from './components/adtrBatch'
import AdtrBatchUpload from './components/adtrBatchUpload'
import StatusHistoryUploadDocuments from './components/statusHistoryUploadDocuments'
import CommonService from './services/CommonService'
import IssuanceDashboard from './components/issuanceDashboard'

function App() {
  const { instance, accounts } = useMsal();
  const isAuthenticated = accounts.length > 0;
  const loginUsername = accounts[0] ? accounts[0].username : "";
  const idleTimerRef = useRef(null);
  const sessionTimeoutRef = useRef(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [tokenData, setTokenData] = useState(null);

  useEffect(()=> {
    CommonService.showHideSpinner(true);

    const getUser = async() => {
      if (accounts.length > 0) {
        const request = {
            scopes: ["openid"],
            account: accounts[0]
        };
        try {
          await instance.acquireTokenSilent(request)
          .then((response) => {
            localStorage.setItem("token", response.idToken);
            let pathName = sessionStorage.getItem('pathname') ?? "/";
            if(window.location.pathname !== pathName) {
              sessionStorage.removeItem("pathname");
              window.location.pathname = pathName;
            }
            setTokenData(response.idToken);
          }).finally(()=>{
            CommonService.showHideSpinner(false);
          });
        } catch (error) {
          console.log("error...");
          instance.logout().catch(e => {
            console.error(e);
          });     
        }
      };
    }
  getUser();
   // eslint-disable-next-line react-hooks/exhaustive-deps
}, [accounts]);

  const onIdle = () => {
    if(isAuthenticated){
      console.log('User is idle');
      setModalIsOpen(true);
      sessionTimeoutRef.current = setTimeout(logOut, 300000);
    }

  }

  const logOut = () => {
    localStorage.removeItem("token");
    sessionStorage.clear();
    console.log('User has logged out');
    setModalIsOpen(false);
    instance.logout().then((response) => {
      toast.info("You have been logged-out of the system due to inactivity. Please login again." , {autoClose: false, closeOnClick: true})
    }
     
    ).catch(e => {
      console.error(e);
  });
    
}

const stayActive = () => {
  console.log('User is active');
  setModalIsOpen(false);
  clearTimeout(sessionTimeoutRef.current);
}

const idletimer = useIdleTimer({
  crossTab: true,
  ref: idleTimerRef,
  timeout: 55 * 60000,
  onIdle: onIdle
})

  return (
    <>
      <UserContext.Provider value={loginUsername}>
      { (!isAuthenticated || tokenData === null) ? <Routes><Route path="*" Component={Login} /></Routes> :
      <Layout user={accounts[0]}>
        <Routes>
        <Route exact path="/" Component={Dashboard} />
        <Route exact path="/:reportType/reports" Component={Reports} />
        <Route exact path="/admin/associations" Component={Associations} />
        <Route exact path="/adtr-batch-download" Component={AdtrBatch} />      
        <Route exact path="/adtr-batch-upload" Component={AdtrBatchUpload} />      
        <Route exact path="/mello-roos/:cdiac" Component={MelloRoos} />   
        <Route exact path="/mello-roos-yfs/:cdiac" Component={MelloRoosYFS} />         
        <Route exact path="/mkr-local-obligors/:cdiac" Component={MKRLocalObligors} />      
        <Route exact path="/mkr-local-obligors-yfs/:cdiac" Component={MKRLocalObligorsYFS} />      
        <Route exact path="/mkr-authority-issue/:cdiac" Component={MKRAuthorityIssue} />      
        <Route exact path="/mkr-authority-issue-yfs/:cdiac" Component={MKRAuthorityIssueYFS} /> 
        <Route exact path="/adtr/:cdiac" Component={Adtr} />   
        <Route exact path="/adtr-yfs/:cdiac" Component={AdtrYFS} />   
        <Route exact path="/dashboard" Component={Dashboard} />      
        <Route exact path="/dashboard/report-proposed-sale/:issueId" Component={ProposedSaleReport} />
        <Route exact path="/dashboard/report-final-sale/:finalSaleId" Component={FinalSaleReport} />
        <Route exact path="/report-private-sale-reason" Component={PrivateSaleReasonReport} />
        <Route exact path="/dashboard/draw-on-reserve/:drawId" Component={DrawOnReserve} />
        <Route exact path="/status-history-upload-documents" Component={StatusHistoryUploadDocuments} />
        <Route exact path="/status-history-upload-documents/:cdiacNbr" Component={StatusHistoryUploadDocuments} />
        <Route exact path="/issuance-dashboard/:cdiacNumber" Component={IssuanceDashboard} />
        <Route exact path="/logout" Component={Logout} /> 
        </Routes>
      </Layout>      
      }

      {/* <Routes>
      <Route exact path="/issuerRegistrationForm" Component={IssuerRegistrationForm} />
      <Route exact path="/filerRegistrationForm" Component={FilerRegistrationForm} />
      </Routes> */}
    </UserContext.Provider>
    <ToastContainer closeButton={false} position="top-right" />
    <Modal show={modalIsOpen} style={{maxWidth: '500px', margin: '0 auto'}}>
      <Modal.Header>
      <h3>Session Timeout in five (5) minutes</h3>
      </Modal.Header>
      <Modal.Body>
      <p>You're being timed out due to inactivity.</p>
      <p>Please choose to stay signed in, or to logoff. Otherwise you will logged off automatically.</p>
      </Modal.Body>
      <Modal.Footer>
      <div className="fright">
        <Button variant="default"
          onClick = {logOut}> Log Out
        </Button>{" "}
        <Button
          onClick = {stayActive}> Keep signed in
        </Button>
      </div>
      </Modal.Footer>
    </Modal>
    <div idletimer={idletimer} />
    </>
  );
}

export default App;

import { Form } from 'react-bootstrap'
import {useState, useEffect} from 'react'
import Card  from '../ui/Card'
import StatusHistory from './statusHistory'
import { validateFormFields } from '../../utils'
import UploadedDocuments from './uploadedDocuments'
import CommonService from '../../services/CommonService'
import BaseService from '../../services/BaseService'
import {useNavigate, useParams} from 'react-router-dom'
import { useMsal } from '@azure/msal-react'

const StatusHistoryUploadDocuments = (props) => {
    const {instance} = useMsal();
    const [showUploadedDocuments, setShowUploadedDocuments] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [showStatusHistory, setShowStatusHistory] = useState(false);
    const [data, setData] = useState([]);
    const user = JSON.parse(sessionStorage.getItem('user'));
    const navigate = useNavigate();
    let params = useParams();

    const [searchParams, setSearchParams] = useState({
      "cdiacNo" : "",
      "historyType" : "",
    });

    useEffect(()=>{
      setSearchParams(prevState => ({
      ...searchParams, 
        cdiacNo:  params.cdiacNbr
      }))
    },[]);// eslint-disable-line react-hooks/exhaustive-deps
    const fetchData = async () => {
      CommonService.showHideSpinner(true);
      var obj = {
        msgId: null,
        sysId: null,
        opr: searchParams.historyType === "Status-History" ? "getQuickRetrievalStatusHistory" : "getQuickRetrievalUploadedDocuments",
        hdrStruct: null,
        queryParam: null,
        reqData: {
          "cdiacNo" : searchParams.cdiacNo,
          "issuerId": user.issuerId 
        },
      };
  
      await BaseService.processRequest(obj)
        .then((res) => res)
        .then((result) => {
          if (result.data.errors && result.data.errors.length > 0){
            CommonService.handleServerResponse(result.data, instance);
            setData([])
          } else {
            Object.keys(result.data.responses).forEach((key) => {
              setData(result.data.responses[key])
            });
            
            if(searchParams.historyType === "Status-History"){
              setShowUploadedDocuments(false) 
              setShowStatusHistory(true)
            } else {
              setShowStatusHistory(false) 
              setShowUploadedDocuments(true)
            }
          }  
          CommonService.showHideSpinner(false);
      });
      
    }
    const handleChange = (e, key) => {
      const {name, value} = e.target
      setSearchParams(prevState=>({
        ...searchParams, 
          [name]:  value
        })
      )
    }

    const handleSubmit = (e) => {
      e.preventDefault()
      const form = e.target.closest('form#quick-retrieval');
        let formErrors = validateFormFields(form.elements);
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {  
            setFormErrors({})
            fetchData()
        }
    }

    return (
        <form onSubmit={(e) => e.preventDefault()}>
        <Card>
          <div className="panel panel-default">
              <div className="panel-heading">
                <div className="container">
                    <div id="packet_id" className="panel-title" >
                        <h1 className="menu-title">Quick Retrievals</h1>
                    </div>
                </div>
              </div>
              <div className="panel-body">
                <div className="container">
                  <div className="form">
                    <form id="quick-retrieval">
                      <div className="control">
                        <label><span className="required">*</span>CDIAC #:</label>
                        <input type="text" name="cdiacNo" value={searchParams.cdiacNo} data-validations="required" onChange={(e)=>handleChange(e, 'cdiacNo')} placeholder="XXXX-XXXX"/>
                        {formErrors.cdiacNo && <p className="error">This field is required.</p>}
                      </div>
                      <div>
                      <Form.Check
                              inline
                              label="Reporting History"
                              value="Status-History"
                              name="historyType"
                              id="statusHistory"
                              type="radio"
                              checked={searchParams.historyType === 'Status-History'}
                              onChange={(e)=>handleChange(e)}
                              data-validations="required"
                          />
                      <Form.Check
                              inline
                              label="Uploaded Documents"
                              value="Uploaded-Documents"
                              name="historyType"
                              id="uploadedDocuments"
                              type="radio"
                              checked={searchParams.historyType === 'Uploaded-Documents'}
                              onChange={(e)=>handleChange(e)}
                              data-validations="required"
                          />
                      </div>
                      {formErrors.historyType && <p className="error">Please select one option.</p>}

                      <div className="mt-4" style={{overflow: 'auto'}}>
                        <button style={{float: 'right'}} className="custom-button" type="Submit" onClick={(e)=>handleSubmit(e)}>Search</button>
                        <button type="button" className="custom-button" onClick={(e)=>navigate('/dashboard')}>Back to Dashboard</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>                 
             
          </div>
          
          {showUploadedDocuments && <UploadedDocuments uploadedDocuments={data} />}         
          {showStatusHistory && <StatusHistory statusHistory={data} />}         
           
        </Card>
        </form>
    )
}

export default StatusHistoryUploadDocuments

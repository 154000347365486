import {useState} from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const ContextModal = ({onModalClose, cdiacNo, finalSaleId, markRoosLocalOblig }) => {
    const navigate = useNavigate();    
    const [btnDisabled, setBtnDisabled] = useState(true);
    const handleSubmit = () => {
        onModalClose();
        navigate(`/mkr-local-obligors-yfs/${cdiacNo}`, {state:{appState: {finalSaleId, markRoosLocalOblig}}});
    }
    const handleChange = (e) => {
        if(e.target.checked) {
         setBtnDisabled(false);
        } else {
         setBtnDisabled(true);
        }
    }
    return (
        <Modal show={true} onHide={onModalClose} backdrop="static" keyboard={false}  size="lg">
            <Modal.Header closeButton>
            <Modal.Title>MARKS ROOS YEARLY FISCAL STATUS REPORT FOR LOCAL OBLIGORS</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <div className="mb-10">
                <p>Completion and submittal of this form to the California Debt and Investment Advisory Commission will assure your compliance with California State law.</p>
                
                <p>Section 6599.1(b) of the California Government Code requires any joint powers authority (“Authority”) selling bonds pursuant to the Act, on or after January 1, 1996, which uses the proceeds of the Marks-Roos bonds to acquire one or more local obligations or transfers funds to a local obligor under the terms of a debt contract between the Authority and the Local Obligor, to report annually on the fiscal status of the Authority bonds and the local obligations acquired until the final maturity of the authority bonds. These financings are identified as reportable Marks-Roos issues. The report must be submitted to CDIAC no later than October 30 each year.</p>
                
                <p>If the local obligor is also subject to annual reporting under Government Code Section 53359.5 (the Mello-Roos annual fiscal reporting requirement), a separate report must be submitted to satisfy reporting under that statute.</p>
               </div>
               <div>
                    <Form.Check inline type="checkbox" id="acknowledge" label="I Acknowledge" onChange={e=>handleChange(e)}/>
               </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className="custom-button-secondary btn-secondary" onClick={onModalClose}>
                    Close
                </Button>
                <Button className="custom-button" disabled={btnDisabled} onClick={handleSubmit}>
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ContextModal

import {useState,  useContext} from 'react'
import Card from '../ui/Card'
import {Row, Col, Form, Modal} from 'react-bootstrap'
//import { validateFormFields } from '../../utils/index'
import CurrencyInput from '../formElements/CurrencyInput'
import { IssuanceDetailContext } from '../../UseContext';
import CommonService from "../../services/CommonService";
import { runValidations , validateFormFields} from '../../utils'
import {UserContext} from "../../UseContext";
import FinalSaleReportService from '../../services/FinalSaleReportService'
import { toast } from 'react-toastify';
import { FormInput } from '../formElements/FormInput'
import {MDBTooltip} from 'mdbreact'
import {AiFillQuestionCircle} from 'react-icons/ai'

const IssuerDetails = (props) => {
    return (
        <Modal show={true} onHide={props.onModalClose}>
            <Modal.Header closeButton>
                <h1>Issuer Details</h1>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={6}>
                        <label>Address Line1</label>
                        <Form.Control type="text" readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>Address Line2</label>
                        <Form.Control type="text" readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>Address Line3</label>
                        <Form.Control type="text" readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>Address Line4</label>
                        <Form.Control type="text" readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>City</label>
                        <Form.Control type="text" readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>State</label>
                        <Form.Control type="text" readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>ZipCode</label>
                        <Form.Control type="text" readOnly/>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="custom-button" onClick={props.onModalClose}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}
const Issuance = (props) => {
    const { markRoosAuthorityDetails } = props;
    const issuanceDetail = useContext(IssuanceDetailContext);
    const [showIssuerDetails, setShowIssuerDetails] = useState(false);
    const [stateValues, setStateValues] = useState({
        totalIssuanceCostAmt : markRoosAuthorityDetails !== undefined ? markRoosAuthorityDetails.totalIssuanceCostAmt : null,
        reserveMinBalInd : markRoosAuthorityDetails.reserveMinBalInd,
        reserveMinBalAmt: markRoosAuthorityDetails.reserveMinBalAmt,
    })
    const [formErrors, setFormErrors] = useState({});
    const loginUser = useContext(UserContext);
    
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    today = mm + '/' + dd + '/' + yyyy;

    const handleChange = (e) => {
        const {name, value} = e.target;
        
        setStateValues(prevState => ({
            ...stateValues, [name]: value
        })
        )
    }

    const validateForm = (e) => {
        let form = e.target.closest('form#issuanceForm');
        let formErrors = validateFormFields(form.elements);
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {
            setFormErrors({});
            updateMarkRoosAuthorityIssuanceCost();
            props.openWidget('fundBalance');
       }
    }

    const updateMarkRoosAuthorityIssuanceCost = async() => {

        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "updateMarkRoosAuthorityIssuanceCost",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {  "id": markRoosAuthorityDetails.markRoosAuthorityId, 
                "env" : "E", 
                "markRoosAuthority":{
                "totalIssuanceCostAmt" : stateValues.totalIssuanceCostAmt,
                "reserveMinBalInd" : stateValues.reserveMinBalInd,
                "reserveMinBalAmt" : stateValues.reserveMinBalAmt,
                "lastUpdateUser":  CommonService.getUserName(loginUser),
                "emailId": loginUser,
                "contactId": markRoosAuthorityDetails.contactId
                }
            }
        };
    
       await FinalSaleReportService.updateMarkRoosAuthorityIssuanceCost(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message)
            }
            else{
                props.updateMarkRoosAuthorityDependencies(result.data.responses[0], 'mkrAuthorityData');
                toast.success("Issuance updated Successfully!!");
            }

        });
    }

    const validateField = (e) => {
        if ( e.target.dataset['validations'] )
            runValidations(e) 
    }

    return (
        <Card>
            <form className="form" id="issuanceForm">
                <h3>Issuance</h3>
            <Row>
            <Col>
                <FormInput label="Authority Issuer" type="text" value={issuanceDetail.issuerName} disabled helpText="Prepopulated from the Issuer Name of the Report of Final Sale."/>
            </Col>
            </Row>
            <Row>
            <Col>
                <FormInput label="Issue Name" type="text" value={issuanceDetail.issueName} disabled helpText="Prepopulated from data submitted on the Report of Final Sale."/>
            </Col>
            </Row>
            <Row>
                <Col md={6} style={{display: 'flex'}}>
                    <label>Senior Issue:
                    <MDBTooltip domElement tag="span" placement="right">
                        <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                        <span>Indicate whether this Authority issue is structured as a Senior in a Senior/Subordinate series. Bonds structured as such are required to file a Yearly Fiscal Status Report for each series.</span>
                    </MDBTooltip>
                    </label>
                    <Form.Check inline type="radio"  checked={issuanceDetail.seniorInd === "Y"}  label="Yes" disabled />
                    <Form.Check inline type="radio"  checked={issuanceDetail.seniorInd === "N"}  label="No" disabled />
                </Col> 
                <Col md={6} style={{display: 'flex'}}>
                    <label>Subordinate Issue:
                    <MDBTooltip domElement tag="span" placement="right">
                        <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                        <span>Indicate whether this Authority issue is structured as a Subordinate in a Senior/Subordinate series. Bonds structured as such are required to file a Yearly Fiscal Status Report for each series.</span>
                    </MDBTooltip>
                    </label>
                    <Form.Check inline type="radio"  checked={issuanceDetail.subordinateInd === "Y"}  label="Yes" disabled />
                    <Form.Check inline type="radio"  checked={issuanceDetail.subordinateInd === "N"}  label="No" disabled />

                </Col> 
            </Row>
            <Row>
            <Col>
                <FormInput label="Project Name" type="text" value={issuanceDetail.projectName} disabled 
                helpText="Prepopulated from data submitted on the Report of Final Sale, this field includes the name of the project(s) for which the debt proceeds will be used."/>
            </Col>
            </Row>
            
            <Row>
                <Col>
                    <FormInput label="Actual Sale Date" type="text" value={CommonService.dateFormatter(issuanceDetail.actualSaleDate)} disabled
                    helpText="Prepopulated from the Report of Final Sale, the Actual Sale Date is the date this debt issue was sold."/>
                </Col>
                <Col>
                    <FormInput label="Settlement Date" type="text" value={CommonService.dateFormatter(issuanceDetail.settlementDate)} disabled
                    helpText="Prepopulated from the Report of Final Sale, this is the closing date of the transfer of assets or rights to use from creditor to the issuer in exchange for delivery of the instruments or evidence of indebtedness."/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormInput label="Original Principal Amount" type="text" value={CommonService.currencyFormatter(issuanceDetail.principalSaleAmt)} disabled
                    helpText="Prepopulated from the Report of Final Sale, this is the original principal amount of the bonds."/>
                </Col>
                <Col>
                    <FormInput label="Date of Filing" type="text" value={CommonService.dateFormatter(markRoosAuthorityDetails.filingDate)} disabled
                    helpText="Prepopulated from the Report of Final Sale, this is the date the Report of Final Sale was filed."/>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <div className="control">
                        <CurrencyInput 
                        placeholder="0.00" 
                        label="Total Issuance Costs" 
                        name="totalIssuanceCostAmt" 
                        type="text" 
                        value={stateValues.totalIssuanceCostAmt ? stateValues.totalIssuanceCostAmt  : ""}
                        onChange={(e)=>handleChange(e)} 
                        onBlur={e=>validateField(e)}
                        error={formErrors.totalIssuanceCostAmt} 
                        validations={[issuanceDetail.isFirstIssuanceCost ? "" : "required", "max:999999999999.99"]}
                        isrequired={issuanceDetail.isFirstIssuanceCost ? false : true }
                        disabled={issuanceDetail.isFirstIssuanceCost}
                        helpText="Indicate the bond issue’s Total Issuance Costs (underwriter’s discount, bond counsel and financial advisor fees, printing, insurance and ratings) for the initial Marks-Roos Authority Yearly that were paid from the bond proceeds. These costs are only to be reported in the initial filing. This field is left blank in subsequent report filings."
                        />
                    </div>
                </Col>
                <Col md={6}>
                    <div className="control">
                        <CurrencyInput 
                        placeholder="0.00" 
                        label="Total Cost of Issuance from Report of Final Sale" 
                        name="totalIssuanceAmt" 
                        type="text" 
                        defaultValue={issuanceDetail.totalIssuanceAmt ? issuanceDetail.totalIssuanceAmt  : ""}
                        validations={["max:999999999999.99"]}
                        disabled
                        helpText="Read-only. Prepopulated from cost of issuance data submitted on the RFS."
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                <div>
                    <label>Was a Reserve Fund established with proceeds of this debt issue?
                    <MDBTooltip domElement tag="span" placement="right">
                        <span>{' '}<AiFillQuestionCircle className="help-icon"/></span>
                        <span>Indicate if the Authority funded a reserve using the proceeds of its bond issue.</span>
                    </MDBTooltip>
                    </label>
                    <div style={{display: 'flex'}}>
                        <Form.Check inline 
                        type="radio" 
                        name="reserveMinBalInd" 
                        id="reserveMinBalInd_Y"
                        checked={stateValues.reserveMinBalInd === "Y"} label="Yes" value="Y"  
                        data-validations={[issuanceDetail.isFirstIssuanceCost ? "" : "required"]}
                        isrequired={issuanceDetail.isFirstIssuanceCost ? false : true }
                        onChange={(e)=>handleChange(e)}/>
                        <Form.Check inline 
                        type="radio" 
                        name="reserveMinBalInd" 
                        id="reserveMinBalInd_N" 
                        checked={stateValues.reserveMinBalInd === "N" } label="No" value="N"
                        data-validations={[issuanceDetail.isFirstIssuanceCost ? "" : "required"]}
                        isrequired={issuanceDetail.isFirstIssuanceCost ? false : true }
                        onChange={(e)=>handleChange(e)}/>
                    </div>
 
                </div>
                </Col>
            </Row>
            <Row>
                < Col md={6}>
                    <div className="control">
                        <CurrencyInput 
                        placeholder="0.00" 
                        label="Reserve Fund Minimum Balance Amount" 
                        name="reserveMinBalAmt" 
                        type="text" 
                        value={stateValues.reserveMinBalAmt}
                        onChange={(e)=>handleChange(e)} 
                        onBlur={e=>validateField(e)}
                        validations={[stateValues.reserveMinBalInd === "Y" && "required", "max:999999999999.99"]}
                        isrequired = {stateValues.reserveMinBalInd === "Y" && "true"}
                        error={formErrors.reserveMinBalAmt} 
                        helpText="Report the minimum balance of the reserve fund (if any) required according to the financing documents."
                        />
                    </div>
                </Col>
            </Row>
            
            <div className="btn-div">
            <button className="fright custom-button" type="button" onClick={e=>validateForm(e)}>Save & Next</button>
            </div>
            </form>
            {showIssuerDetails && <IssuerDetails onModalClose={()=>setShowIssuerDetails(false)}/>}

        </Card>
    )
}

export default Issuance
